import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { ElMessage } from 'element-plus'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import Apis from './api'

const app = createApp(App)
const catchHttpError = function (error) {
    if(!error){
        throw new Error('Error is not defined')
    }
    if(error.code === 'ECONNABORTED'){
        throw new Error('Request timeout')
    }
    ElMessage.error({
        message: error.message,
        duration: 5000,
        showClose: true
    })
    // console.error(error)
}


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store);
app.provide('$_apis', Apis)
app.provide('$catchHttpError', catchHttpError)

app.use(ElementPlus)
app.use(router).mount('#app')

