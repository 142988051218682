import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import { inject, onMounted, onUnmounted, ref } from 'vue';
const apis = inject('$_apis');
export default {
  setup() {
    const navbarHeight = ref('70px');
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Change '100' based on when you want the navbar to shrink
        navbarHeight.value = '50px'; // Reduced navbar height
      } else {
        navbarHeight.value = '70px'; // Default navbar height
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    return {
      navbarHeight
    };
  },
  computed: {
    firstLetter() {
      return this.$store.state.accountName ? this.$store.state.accountName.charAt(0).toUpperCase() : '';
    },
    avatarStyle() {
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#42b983',
        // Customize as needed
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        fontSize: '20px' // Adjust font size as needed
      };
    },

    isUserLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  methods: {
    handleSelect(index, indexPath) {
      this.activeIndex = index;
      console.log(indexPath);
      this.$router.push(indexPath.route);
    },
    goToLogin() {
      this.$router.push('/login');
    },
    async handleCommand(command) {
      if (command === 'logout') {
        const {
          data
        } = await apis.logout();
        if (!data) {
          ElMessage.error('Logout failed');
          return;
        }
        this.$store.dispatch('logout');
        // Implement your logout logic here
        this.isLoggedIn = false;
      } else if (command === 'profile') {
        this.$router.push('/profile');
      } else if (command === 'settings') {
        this.$router.push('/settings');
      }
    }
  }
};