import { createRouter, createWebHistory } from "vue-router";
const SiteHome = () => import(/* webpackChunkName: "Home" */"@/views/pages/SiteHome.vue")
const UserLogin = () => import(/* webpackChunkName: "Login" */"@/views/pages/UserLogin")
const UserSignUp = () => import(/* webpackChunkName: "SignUp" */"@/views/pages/UserSignUp")
const BeforeStream = () => import(/* webpackChunkName: "BeforeStream" */"@/views/pages/BeforeStream")
const StreamTranslator = () => import(/* webpackChunkName: "StreamTranslator" */"@/views/pages/StreamTranslator")
const Terms = () => import(/* webpackChunkName: "Terms" */"@/views/pages/Terms")
const AboutUs = () => import(/* webpackChunkName: "AboutUs" */"@/views/pages/AboutUs")
const ModelDashboard = () => import(/* webpackChunkName: "ModelDashboard" */"@/views/pages/ModelDashboard")

const routes = [
    { path: "/", component: SiteHome },
    { path: "/login", component: UserLogin },
    { path: "/signup", component: UserSignUp },
    { path: "/stream-setup", component: BeforeStream},
    { path: "/stream", component: StreamTranslator},
    { path: "/terms", component: Terms},
    { path: "/about", component: AboutUs},
    { path: "/model", component: ModelDashboard},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
