// src/api/api.js
import axios from 'axios';

// ... (rest of the axios setup)
axios.defaults.timeout = 60000;
axios.defaults.baseURL = '';

// Add a request interceptor
axios.interceptors.request.use((config) => {
    // Do something before request is sent
    return config;
});

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // Do something with response data
        return response;
    },
    (error) => {
        // Do something with response error
        if (error && error.response) {
            const { status } = error.response;
            switch (status) {
                case 401:
                    error.message = '未授权，请登录';
                    break;
                default:
                    error.message = error.response.data?.message || error.message;
                    break;
            }
        }
        return Promise.reject(error);
    });

export default axios;