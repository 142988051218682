import http from '@/core/http'

export default {
    login (data) {
        return http.post('/login', data);
    },
    logout () {
        return http.post('/logout');
    },
    getAccountInfo () {
        return http.get('/ai/me');
    },
    getHomeInfo () {
        return http.get('/ai/home');
    },
    registerAccount (data) {
        return http.post('/register', data);
    }
}