import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NavigationBar = _resolveComponent("NavigationBar");
  const _component_el_header = _resolveComponent("el-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_SiteFooter = _resolveComponent("SiteFooter");
  const _component_el_footer = _resolveComponent("el-footer");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, {
    class: "ai-main-site"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_header, {
      style: {
        "padding": "0 0"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_NavigationBar)]),
      _: 1
    }), _createVNode(_component_el_main, {
      style: {
        "padding": "0 0"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    }), _createVNode(_component_el_footer, {
      style: {
        "background-color": "dimgrey",
        "height": "auto"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_SiteFooter)]),
      _: 1
    })]),
    _: 1
  });
}