// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            isLoggedIn: false,
            accountName: '',
            email: '',
            phoneNumber: '',
            status: null,
        };
    },
    mutations: {
        setLoggedIn(state, status) {
            state.isLoggedIn = status;
        },
        setAccountInfo(state, account) {
            state.accountName = account.accountName;
            state.email = account.email;
            state.phoneNumber = account.phoneNumber;
            state.status = account.status;
        }
    },
    actions: {
        login({ commit }, status) {
            commit('setLoggedIn', status);
        },
        logout({ commit }) {
            commit('setLoggedIn', false);
        },
        setAccountInfo({ commit }, account) {
            commit('setAccountInfo', account);
        }
    },
});
